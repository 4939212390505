import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import EmailIcon from '@material-ui/icons/Email';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

const useStyles = makeStyles((theme) => ({
  fab: {
    margin: theme.spacing(1),
  },
  absolute: {
    position: 'absolute',
    bottom: theme.spacing(2),
    right: theme.spacing(3),
  },
}));

function Contact() {
	const classes = useStyles();
  return (
  	<Grid container
        direction="row"
        justify="center"
        alignItems="center">
      <Link href="https://www.linkedin.com/in/wenhong-lam/">
        <Tooltip title="LinkedIn" aria-label="LinkedIn">
          <Fab color="primary" className={classes.fab}>
            <LinkedInIcon />
          </Fab>
        </Tooltip>
      </Link>
      <Link href="mailto:lamwh55@gmail.com">
        <Tooltip title="Email" aria-label="Email">
          <Fab color="primary" className={classes.fab}>
            <EmailIcon />
          </Fab>
        </Tooltip>
      </Link>
      <Link href="https://github.com/wenhongg/">
        <Tooltip title="GitHub" aria-label="GitHub">
          <Fab color="primary" className={classes.fab}>
            <GitHubIcon />
          </Fab>
        </Tooltip>
      </Link>
    </Grid>
  );
}

export default Contact;