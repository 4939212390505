import React from "react";
import logo from '../logo.svg';

import NavBar from "../components/NavBar.js";
import ProjectsView from '../components/ProjectsView.js';
import ProjectExperiences from '../components/ProjectExperiences.js';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

import header_image from "../assets/convertible_header.png";

const useStyles = makeStyles((theme) => ({
  image: {
    height: '100%',
    width: '100%',
    filter: 'brightness(50%)',
    zIndex: -1
  },
  header: {
  	position: 'relative',
  	'text-align': 'center'
  },
  headerWords: {
  	position: 'absolute',
  	top: '50%',
  	left: '50%',
  	transform: 'translate(-50%, -50%)',
  	color: 'white'
  },
  container: {
  	'font-family': 'Nunito',
  	'text-align': 'center'
  },
}));

function ProjectsPage() {
	const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <NavBar color="black" />
      <div className={classes.header}>
      	<img className={classes.image} src={header_image} />
      	<div className={classes.headerWords}>
      		<h1>PROJECTS</h1>
      		<h3>Some of the fun stuff I've done recently.</h3>
      	</div>
    	</div>
      
      <ProjectExperiences />
    </Container>
  );
}

export default ProjectsPage;