import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles({
  card: {
    margin: 10
  },
  media: {
    'padding-top': '56.25%'
  },
});

function ProjectsCard(props) {
  const classes = useStyles();

  let image = props.project.media;
  let title = props.project.title;
  let description = props.project.description;
  
  return (
    <Card raised={true} className={classes.card}>
      <CardActionArea>
        <CardMedia
            className={classes.media}
            image={image}
            title={title}
        />
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            {title}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            {description}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
}

export default ProjectsCard;