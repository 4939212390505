import React from "react";

import { Grid, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Link as RouterLink } from 'react-router-dom';

import Tooltip from '@material-ui/core/Tooltip';

const useStyles = makeStyles((theme) => ({
  navbar: {
    'text-align': 'center',
    'paddingBottom' : 15,
    'font-family' : 'Nunito'
  },
  cells: {
    height: 50,
    width: 150
  },
  links: props => ({
    color: props.color,
    '&:hover': {
      color: "#769CC8",
      textDecoration: 'none'
    }
  })
}));

function NavBar(props) {
  var classes = useStyles(props);
  var preventDefault = (event) => event.preventDefault();


  return (
    <div className={classes.navbar}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={3} className={classes.cells}>
          <Link component={RouterLink} to="/about" className={classes.links}>
            <h3>About Me</h3>
          </Link>
        </Grid>
        <Grid item xs={3} className={classes.cells}>
          <Link component={RouterLink} to="/works" className={classes.links}>
            <h3>Work Experience</h3>
          </Link>
        </Grid>
        <Grid item xs={3} className={classes.cells}>
          <Link component={RouterLink} to="/projects" className={classes.links}>
            <h3>Projects</h3>
          </Link>
        </Grid>
        <Grid item xs={3} className={classes.cells}>
          <Link component={RouterLink} to="#" className={classes.links}>
            <Tooltip title="Coming Soon!">
              <h3>Travels</h3>
            </Tooltip>
          </Link>
        </Grid>
      </Grid>
    </div>
  );
}

export default NavBar;