import React from "react";
import logo from '../logo.svg';

import NavBar from "../components/NavBar.js";
import ProjectsView from '../components/ProjectsView.js';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import { getHeaderImage } from "../utils/travelsController.js";
import CoverMedia from "../components/CoverMedia";
import header_image from "../assets/alaska_cropped.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
  	'font-family': 'Nunito',
  	'text-align': 'center'
  },
  alert: {
    display: 'flex',
    'flex-direction': 'column',
    'justify-content': 'center',
    'text-align': 'center'
  }
}));

function TravelsPage() {
	const classes = useStyles();
  let headerImage = getHeaderImage();
  return (
    <>
      <CoverMedia media={headerImage} />
      <Container maxWidth="lg" className={classes.container}>
        <NavBar color="white" />
      </Container>
    </>
  );
}

export default TravelsPage;