import React from "react";

import NavBar from "../components/NavBar.js";
import AboutMe from "../components/AboutMe.js";

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import { Grid } from '@material-ui/core';
import header_image from "../assets/myheader_cropped.jpg";

const useStyles = makeStyles((theme) => ({
  image: {
    height: '100%',
    width: '100%'
  },
  container: {
  	'font-family': 'Nunito',
  	'text-align': 'center'
  }
}));

function AboutPage() {
	const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <NavBar color="black" />
      <AboutMe />
    </Container>
  );
}

export default AboutPage;
