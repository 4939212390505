import React from "react";

import Container from '@material-ui/core/Container';
import { Grid, Link } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import { getProjects_list } from "../utils/projectsController.js";

const useStyles = makeStyles((theme) => ({
  container: {
    'padding': 30
  },
  entry: {
    'margin': 30
  },
  image: {
    width: '250px', /* width of container */
    height: '250px', /* height of container */
    'object-fit': 'cover',
    
  },
  frame: {
    'text-align': 'left',
    'paddingBottom' : 15,
    'paddingTop' : 15,
    'font-family' : 'Nunito'
  },
  cell_image: {
    width: '350px', /* width of container */
    height: '350px', /* height of container */
    textAlign: 'center'
  },
  cell_text: {
    textAlign: 'left',
    verticalAlign: 'text-top', 
    '& ul li': {
      padding: 15
    }
  },
  links: props => ({
    color: "#2E86C1",
    textDecoration: 'underline',
    '&:hover': {
      color: "#5DADE2"
    }
  })
}));

function ProjectExperiences() {
  const classes = useStyles();

  let projects = getProjects_list();
  // Individual work entry
  function ProjectEntry(entry) {
    return(
      <Grid container className={classes.entry}>
        <Grid item xs={12} sm={12} md={4} className={classes.cell_image} >
        <Box component="img" src={entry.media} className={classes.image} />
        </Grid>
        <Grid item xs={12} sm={12} md={8} className={classes.cell_text}>
            {entry.link ? 
              (<Link href={entry.link} className={classes.links}><h1>{entry.title}</h1></Link>) : 
              (<h1>{entry.title}</h1>)
            }
            {entry.description}
        </Grid>
      </Grid>
    )
  }

  let projectsComponent = projects.map(ProjectEntry)
  return (
    <Container maxWidth="lg" className={classes.container}>
    <div className={classes.frame}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        {projectsComponent}
      </Grid>
    </div>
  </Container>
  );
}

export default ProjectExperiences;