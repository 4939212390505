import React from "react";

import thryft_image from "../assets/thryft_logo.JPG";
import brevity_image from "../assets/brevity.JPG";
import telegram_image from "../assets/teleg.png";
import jigsaw_image from "../assets/jigsaw.jpg";
import capsol_image from "../assets/capsol_logo.PNG";


import dso_logo from "../assets/company_logos/dso_logo.png";
import paypal_logo from "../assets/company_logos/paypal_logo.png";
import facebook_logo from "../assets/company_logos/facebook_logo.png";

import brevity_logo from "../assets/project_logos/brevity.jpg";
import kirby_logo from "../assets/project_logos/kirby_game.JPG";
import starter_logo from "../assets/project_logos/starter_kit.jpg";

export function getProjects(){
	return [
      {
        //media: `url(${header_image})`,
        media: `${brevity_image}`,
        title: "Brevity",
        description: "Application for productivity. In progress."
      },
      /*
      {
        //media: `url(${header_image})`,
        media: `${thryft_image}`,
        title: "Thryft",
        description: "Worked on Thryft's book deposit system for some time."
      },
      */
      {
        //media: `url(${header_image})`,
        media: `${telegram_image}`,
        title: "ChambersBot",
        description: "Telegram bot to set reminders."
      },
      {
        //media: `url(${header_image})`,
        media: `${capsol_image}`,
        title: "Capsol",
        description: "Smart pillbox with mobile app integration. Submitted to IDEAHACKS 2020."
      },
      {
        //media: `url(${header_image})`,
        media: `${jigsaw_image}`,
        title: "Jigsaw",
        description: "Skill-sharing platform. Submitted to AngelHack Global Hackathon."
      }
    ];
}
// TODO
export function getProjects_list(){
  return [
    {
      media: `${starter_logo}`,
      title: "Django-React Starter Kit",
      link: "https://github.com/dailybruin/starter.django-react",
      description: (
        <>
          <p>
            Developed this during my work as a software engineer with the <a href="https://dailybruin.com">Daily Bruin</a>. This starter kit allows for the rapid development of internal tools by handling most of the boilerplate in terms of routing.
          </p>
          <p>
            It's essentially a Docker container which implements NGINX infrastructure to host both a React Frontend and a Django Backend from a single endpoint.
          </p>
        </>
      )
    },
    {
      media: `${kirby_logo}`,
      title: "Kirby's Christmas Dinner",
      link: "",
      description: (
        "Move around on a slippery winter wonderland, avoiding pellets from the aerial Kirby! Developed as part of UCLA's CS174A (Introduction to Computer Graphics)."
      )
    },
    {
      media: `${brevity_logo}`,
      title: "Brevity",
      link: "",
      description: (
        "Enhance your productivity with timers for every need. Work in progress."
      )
    },
  ]
}

export function getWorkExperiences(){
  return [
    {
      media: `${facebook_logo}`,
      title: "Facebook",
      start: "June 2021",
      end: "September 2021",
      position: "Software Engineer Intern",
      location: "Menlo Park (Remote)",
      description: (
        <ul>
          <li>Internship in progress! Having a great time.</li>
        </ul>
      )
    },
    {
      media: `${paypal_logo}`,
      title: "PayPal",
      start: "July 2020",
      end: "November 2020",
      position: "Software Engineer Intern, Payments Team",
      location: "Singapore (Remote)",
      description: (
        <ul>
          <li>Refactored Java Spring core backend wallet service, reducing latency for API serving 13 million hits daily and
improving maintainability of codebase</li>
          <li>Developed Node.js developer tool with Probot framework and deployed it to GCP, to streamline code review process
through smart recommendation of reviewers and trusted committers, improving review quality and Time To Merge</li>
          <li>Worked in an Agile environment to add unit and functional tests with JUnit and TestNG to core services to improve code coverage</li>
        </ul>
      )
    },
    {
      media: `${dso_logo}`,
      title: "DSO National Laboratories",
      start: "July 2019",
      end: "September 2019",
      position: "Software Intern",
      location: "Singapore",
      description: (
        <ul>
          <li>Using GRASS GIS tools together with Java, I produced distinct feature representations of terrain for multilayer network analysis</li>
          <li>Built and refined feature detection engine with Java Topological Suite and Graphstream</li>
          <li>Developed Graph-based AI Java module enabling generation of strategic boundaries and traversal plans</li>
        </ul>
      )
    },
    {
      media: `${dso_logo}`,
      title: "DSO National Laboratories",
      start: "April 2018",
      end: "June 2018",
      position: "Software Intern",
      location: "Singapore",
      description: (
        <ul>
          <li>Implemented various graph traversals and techniques on the Visual Genome and WebChild commonsense-knowledge bases, enabling cognitive architecture to understand and relate concepts</li>
          <li>Developed Java application with the Gson suite to parse disjointed source files into single usable databases and set up query system, improving runtime and convenience</li>
        </ul>
      )
    }
  ]
}