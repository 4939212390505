import React from "react";
import logo from '../logo.svg';

import NavBar from "../components/NavBar.js";
import WorkExperiences from '../components/WorkExperiences.js';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

import header_image from "../assets/alaska_cropped.jpg";

const useStyles = makeStyles((theme) => ({
  container: {
    'font-family': 'Nunito',
    'text-align': 'center'
  },
  image: {
    height: '100%',
    width: '100%',
    filter: 'brightness(75%)',
    zIndex: -1
  },
  header: {
    position: 'relative',
    'text-align': 'center'
  },
  headerWords: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    'fontSize': 30
  },
  
}));

function WorksPage() {
  const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <NavBar color="black" />
      <div className={classes.header}>
        <img className={classes.image} src={header_image} />
        <div className={classes.headerWords}>
          INTERN EXPERIENCES
        </div>
      </div>

      <WorkExperiences />
    </Container>
  );
}

export default WorksPage;