import React from "react";

import NavBar from "../components/NavBar.js";
import AboutMe from "../components/AboutMe.js";

import Container from '@material-ui/core/Container';
import { makeStyles } from '@material-ui/core/styles';

import header_image from "../assets/fortunecookies.jpg";

const useStyles = makeStyles((theme) => ({
  image: {
    //height: '100%',
    //width: '1000px'

    width: '1000px', /* width of container */
    height: '500px', /* height of container */
    'object-fit': 'cover',
    'object-position': '100% 60%' /* try 20px 10px */ 
  },
  container: {
  	'font-family': 'Nunito',
  	'text-align': 'center'
  }
}));

function FortuneCookiesPage() {
	const classes = useStyles();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <NavBar color="black" />
      <img class={classes.image} src={header_image} />
      <h1>Fortune Cookies</h1>

      <p>Get some here.</p>
    </Container>
  );
}

export default FortuneCookiesPage;
