import React from "react";
import header_image from "../assets/about_cropped_vertical.jpg";

export function getHeaderImage(){
	return header_image;
}

export function getTitle(){
	return "Hi! I'm Wen Hong."
}

export function getDescription(){
	/*
	return "I'm currently a Computer Science junior at UCLA. I was born and raised in Singapore. \
	  			I enjoy traveling, hiking and mountaineering. I enjoy karaoke, boba and playing the piano. \
	  			I enjoy watching MMA, and I train in Brazilian Jiu-Jitsu.";*/
	return (
		<>
			<h2>About Me</h2>
			<ul>
				<li>Rising senior at UCLA, majoring in Computer Science</li>
				<li>Born and raised in Singapore</li>
				<li>Enjoys coding and developing!</li>
				<li>Hoping to get more involved in research work</li>
			</ul>
			<h2>Interests</h2>
			<ul>
				<li>Traveling and hiking</li>
				<li>Skiing</li>
				<li>Brazilian Jiu-Jitsu</li>
			</ul>
			Always eager to learn, discuss and potentially collaborate on projects and ideas!
			Reach me through any of the below channels.
		</>
	);
}