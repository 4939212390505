import React from "react";
import logo from '../logo.svg';

import NavBar from "../components/NavBar.js";
import ProjectsView from '../components/ProjectsView.js';
import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';

import header_image from "../assets/alaska_cropped.jpg";
import PhotoGallery from "../components/PhotoGallery.js";
import { getLikedPics } from "../utils/likesController.js";

const useStyles = makeStyles((theme) => ({
  image: {
    height: '100%',
    width: '100%',
    filter: 'brightness(75%)',
    zIndex: -1
  },
  header: {
  	position: 'relative',
  	'text-align': 'center'
  },
  headerWords: {
  	position: 'absolute',
  	top: '50%',
  	left: '50%',
  	transform: 'translate(-50%, -50%)',
  	color: 'white'
  },
  container: {
  	'font-family': 'Nunito',
  	'text-align': 'center'
  },
  gallery: {
    'padding' : 30
  }
}));

function LikesPage() {
	const classes = useStyles();
  let photos = getLikedPics();
  return (
    <Container maxWidth="lg" className={classes.container}>
      <NavBar color="black" />
      <div className={classes.header}>
      	<img className={classes.image} src={header_image} />
      	<div className={classes.headerWords}>
      		<h1>Here's what I like</h1>
          These things, they spark joy
      	</div>
    	</div>
      <div className={classes.gallery}>
        <PhotoGallery photos={photos} />
      </div>
    </Container>
  );
}

export default LikesPage;