import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

import FrontPage from './pages/FrontPage.js';
import ProjectsPage from './pages/ProjectsPage.js';
import AboutPage from './pages/AboutPage.js';
import FortuneCookiesPage from './pages/FortuneCookiesPage.js';
import WorksPage from './pages/WorksPage.js';
import LikesPage from './pages/LikesPage.js';
import TravelsPage from './pages/TravelsPage.js';

import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/front" render={props => <FrontPage {...props} />} />
      <Route path="/about" render={props => <AboutPage {...props} />} />
      <Route path="/works" render={props => <WorksPage {...props} />} />
      <Route path="/projects" render={props => <ProjectsPage {...props} />} />

      <Redirect from="/" to="/front" />
    </Switch>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

//<Route path="/travels" render={props => <TravelsPage {...props} />} />