import React from "react";
import logo from '../logo.svg';

import { makeStyles } from '@material-ui/core/styles';
import NavBar from "../components/NavBar.js";
import { getFrontBackground, getCurrentLocation } from "../utils/frontPageController.js";
import CoverMedia from "../components/CoverMedia";
import Contact from "../components/Contact.js";

const useStyles = makeStyles((theme) => ({
  frontContainer: {
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',

    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontFamily: 'Wellfleet, cursive',
    textAlign : 'center'
  },
  frontTitle: {
    fontSize: 'calc(30px + 2vmin)'
  },
  frontDescription: {
    fontSize: 'calc(20px + 2vmin)'
  },
  noMargin: {
    marginBottom: '0px',
    marginTop: '0px'
  },
  frontBottom: {
    position: 'absolute',
    bottom: 0,
    fontSize: 'calc(0px + 2vmin)',
    textAlign: 'center'
  }
}));

function FrontPage() {
  const classes = useStyles();
  let frontBackground = getFrontBackground();
  return (
    <>
      <CoverMedia media={frontBackground.media} />
      <div className={classes.frontContainer}>
        <div className={classes.frontTitle}>
          <h1 className={classes.noMargin}>Wen Hong</h1>
          <div className={classes.frontDescription}>
            <p className={classes.noMargin}>is on an adventure in {getCurrentLocation()}.</p>
          </div>
        </div>
        <NavBar color="white" />
        <div className={classes.frontBottom}>
          <p></p>
        </div>
      </div>
    </>
  );
}

export default FrontPage;
