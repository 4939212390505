import React from "react";

import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { getDescription, getTitle, getHeaderImage } from "../utils/aboutController.js";
import Contact from "./Contact.js";

const useStyles = makeStyles((theme) => ({
  image: {
    //height: '100%',
    //width: '1000px'

    maxWidth: '600px', /* width of container */
    maxHeight: '700px', /* height of container */
    'object-fit': 'cover',
    'object-position': '100% 30%' /* try 20px 10px */ 
  },
  frame: {
    'text-align': 'left',
    'paddingBottom' : 15,
    'paddingTop' : 15,
    'font-family' : 'Nunito'
  },
  cells: {
    width: '600px', /* width of container */
    height: '700px', /* height of container */
  },
  text: {
  }
}));

function AboutMe() {
	const classes = useStyles();
  return (
  	<div className={classes.frame}>
      <Grid
        container
        direction="row"
        justify="center"
        alignItems="center"
      >
        <Grid item xs={12} sm={12} md={6} className={classes.cells}>
		     	<img className={classes.image} src={getHeaderImage()} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} className={classes.cells}>
        	<div className={classes.text}>
	          <h1>{getTitle()}</h1>
			  		<h3>
			  			{getDescription()}
			  		</h3>
			  		<Contact />
			  	</div>
        </Grid>
      </Grid>
    </div>
  );
}

export default AboutMe;