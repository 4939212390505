import React from "react";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  pageHeaderImage: {
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    width: '100%',
    height: '100%',
    zIndex: -1,
    filter: 'brightness(50%)'
  },
  frontVideo: {
    position: 'absolute',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    right: 0,
    bottom: 0,
    minWidth: '100%',
    minHeight: '100%',
    zIndex: -1,
    filter: 'brightness(50%)'
  }
}));

function CoverMedia(props){
  const classes = useStyles();
  let frontMedia = props.media;

  if(_isVideo(frontMedia)){
    return (
        <video autoPlay muted loop className={classes.frontVideo}>
          <source src={frontMedia} type="video/mp4" />
        </video>
    )
  } else {
    return (
      <div className={classes.pageHeaderImage}
        style={{
          backgroundImage: frontMedia
        }}>
      </div>
    )
  }
  
}
export default CoverMedia;

function _isVideo(mediaAddress){
  return mediaAddress.endsWith(".mp4");
}

/*


      <div className="page-header-image"
        style={{
          backgroundImage: frontBackground.media
        }}>
      </div>
      
*/