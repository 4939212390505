import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Paper from '@material-ui/core/Paper';
import ProjectsCard from './ProjectsCard.js';

import Grid from '@material-ui/core/Grid';

import { getProjects } from '../utils/projectsController.js';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    'text-align': 'center',
    margin: 10
  }
}));

const cardsPerRow = 2;

function ProjectsView() {
  const classes = useStyles();
  let projects = getProjects();

  let sizeOfCard = 12/cardsPerRow;
  
  function ProjectsRow(props) {
    let projectsList = props.projects;
    let i = 0;

    let row = [];
    for(let i = 0; i < cardsPerRow; i++){
      if(i < projectsList.length){
        row.push(
          <Grid item xs={sizeOfCard}>
            <ProjectsCard project={projectsList[i]} />
          </Grid>
        )
      } else {
        row.push(
          <Grid item xs={sizeOfCard}>
          </Grid>
        )
      }
    }
    return (
      <React.Fragment>
        {row}
      </React.Fragment>
    );
  }

  let j = 0;
  let layout = [];
  while(j + cardsPerRow <= projects.length){
    layout.push(
      <Grid container item xs={12}>
        <ProjectsRow projects={projects.slice(j, j + cardsPerRow)} />
      </Grid>
    )
    j += cardsPerRow;
  }
  if(j < projects.length){
    layout.push(
      <Grid container item xs={12}>
        <ProjectsRow projects={projects.slice(j, projects.length)} />
      </Grid>
    )
  }

  return (
    <div className={classes.root}>
      <Grid container>
        {layout}
      </Grid>
    </div>
  );
}

export default ProjectsView;